body {
    margin-bottom: 100px;
}

address {
    font-weight: bold;
}

.desjardins {
    color: $desjardins;
}
.rouge {
    color: #cc0000;
}
