@charset "UTF-8";
@font-face {
  font-family: oswald;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts//oswald.eot");
  src: url("../assets/fonts//oswald.eot?#iefix") format("embedded-opentype"), url("../assets/fonts//oswald.woff") format("woff"), url("../assets/fonts//oswald.ttf") format("truetype"), url("../assets/fonts//oswald.svg#oswald") format("svg");
}
@font-face {
  font-family: oswald-bold;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts//oswald-bold.eot");
  src: url("../assets/fonts//oswald-bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts//oswald-bold.woff") format("woff"), url("../assets/fonts//oswald-bold.ttf") format("truetype"), url("../assets/fonts//oswald-bold.svg#oswald-bold") format("svg");
}
@font-face {
  font-family: oswald-light;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts//oswald-light.eot");
  src: url("../assets/fonts//oswald-light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts//oswald-light.woff") format("woff"), url("../assets/fonts//oswald-light.ttf") format("truetype"), url("../assets/fonts//oswald-light.svg#oswald-light") format("svg");
}
@font-face {
  font-family: disnat;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts//disnat.eot");
  src: url("../assets/fonts//disnat.eot?#iefix") format("embedded-opentype"), url("../assets/fonts//disnat.woff") format("woff"), url("../assets/fonts//disnat.ttf") format("truetype"), url("../assets/fonts//disnat.svg#disnat") format("svg");
}
@font-face {
  font-family: disnat2;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts//disnat2.eot");
  src: url("../assets/fonts//disnat2.eot?#iefix") format("embedded-opentype"), url("../assets/fonts//disnat2.woff") format("woff"), url("../assets/fonts//disnat2.ttf") format("truetype"), url("../assets/fonts//disnat2.svg#disnat2") format("svg");
}
[class^=icon-],
[class*=" icon-"],
.icon-disnat {
  font-family: "disnat";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  outline: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-disnat2 {
  font-family: "disnat2";
}

/* disnat 2 */
.icon-circle-plus-light:before {
  content: "\e621";
}

.icon-file-web:before {
  content: "\e61d";
}

.icon-file-pdf:before {
  content: "\e61e";
}

.icon-file-excel:before {
  content: "\e61f";
}

.icon-file-word:before {
  content: "\e620";
}

.icon-phone-up:before {
  content: "\e61b";
}

.icon-fax:before {
  content: "\e61c";
}

.icon-windows:before {
  content: "\e60c";
}

.icon-apple:before {
  content: "\e60d";
}

.icon-user-to-user:before {
  content: "\e60e";
}

.icon-students:before {
  content: "\e60f";
}

.icon-book-roof:before {
  content: "\e610";
}

.icon-change:before {
  content: "\e611";
}

.icon-parasol:before {
  content: "\e612";
}

.icon-bill:before {
  content: "\e613";
}

.icon-house:before {
  content: "\e614";
}

.icon-laptop:before {
  content: "\e615";
}

.icon-gear:before {
  content: "\e616";
}

.icon-phone-line:before {
  content: "\e617";
}

.icon-group-line:before {
  content: "\e618";
}

.icon-users-line:before {
  content: "\e619";
}

.icon-user-line:before {
  content: "\e61a";
}

.icon-user:before {
  content: "\e600";
}

.icon-comment:before {
  content: "\e601";
}

.icon-clock-bold:before {
  content: "\e602";
}

.icon-zoom-plus:before {
  content: "\e605";
}

.icon-zoom-minus:before {
  content: "\e603";
}

.icon-zoom:before {
  content: "\e604";
}

.icon-download:before {
  content: "\e607";
}

.icon-file:before {
  content: "\e606";
}

.icon-table:before {
  content: "\e609";
}

.icon-graph-dot:before {
  content: "\e608";
}

.icon-check-light:before {
  content: "\e60b";
}

.icon-arrow-right-light:before {
  content: "\e60a";
}

/* disnat 1 */
.icon-menu:before {
  content: "\e637";
}

.icon-triangle-small-up:before {
  content: "\e638";
}

.icon-triangle-small-down:before {
  content: "\e639";
}

.icon-arrow-up:before {
  content: "\e63a";
}

.icon-arrow-down:before {
  content: "\e63b";
}

.icon-circle-close:before {
  content: "\e628";
}

.icon-book:before {
  content: "\e629";
}

.icon-user-bold:before {
  content: "\e62a";
}

.icon-print:before {
  content: "\e62b";
}

.icon-clock:before {
  content: "\e62c";
}

.icon-circle-arrow-down:before {
  content: "\e62d";
}

.icon-circle-plus:before {
  content: "\e62e";
}

.icon-stat:before {
  content: "\e62f";
}

.icon-check:before {
  content: "\e630";
}

.icon-expert:before {
  content: "\e631";
}

.icon-intermediary:before {
  content: "\e632";
}

.icon-novice:before {
  content: "\e633";
}

.icon-files:before {
  content: "\e634";
}

.icon-direction-up:before {
  content: "\e635";
}

.icon-direction-down:before {
  content: "\e636";
}

.icon-thumb-up:before {
  content: "\e61d";
}

.icon-graph:before {
  content: "\e61e";
}

.icon-compass:before {
  content: "\e61f";
}

.icon-monitor:before {
  content: "\e620";
}

.icon-icon-desjardins:before {
  content: "\e621";
}

.icon-map:before {
  content: "\e622";
}

.icon-mail-closed:before {
  content: "\e623";
}

.icon-movie:before {
  content: "\e624";
}

.icon-print:before {
  content: "\e625";
}

.icon-share:before {
  content: "\e626";
}

.icon-bookmark:before {
  content: "\e627";
}

.icon-font-increase:before {
  content: "\e61b";
}

.icon-font-decrease:before {
  content: "\e61c";
}

.icon-calendar:before {
  content: "\e60e";
}

.icon-circle-clock:before {
  content: "\e60f";
}

.icon-circle-dollars:before {
  content: "\e610";
}

.icon-circle-interrogation:before {
  content: "\e611";
}

.icon-circle-map:before {
  content: "\e612";
}

.icon-circle-user:before {
  content: "\e613";
}

.icon-discussion:before {
  content: "\e614";
}

.icon-loupe:before {
  content: "\e615";
}

.icon-mail:before {
  content: "\e616";
}

.icon-triangle-left:before {
  content: "\e617";
}

.icon-triangle-right:before {
  content: "\e618";
}

.icon-up-down:before {
  content: "\e619";
}

.icon-user-add:before {
  content: "\e61a";
}

.icon-up-down2:before {
  content: "\e600";
}

.icon-arrow-left:before {
  content: "\e601";
}

.icon-arrow-right:before {
  content: "\e602";
}

.icon-circle-arrow-left:before {
  content: "\e603";
}

.icon-circle-arrow-left-hover:before {
  content: "\e604";
}

.icon-circle-arrow-right:before {
  content: "\e605";
}

.icon-circle-arrow-right-hover:before {
  content: "\e606";
}

.icon-close:before {
  content: "\e607";
}

.icon-direction-left:before {
  content: "\e608";
}

.icon-direction-right:before {
  content: "\e609";
}

.icon-lock:before {
  content: "\e60a";
}

.icon-play:before {
  content: "\e60b";
}

.icon-play-hover:before {
  content: "\e60c";
}

.icon-plus:before {
  content: "\e60d";
}

.twitter-typeahead .twitter-typeahead {
  position: inherit !important;
  display: inherit !important;
}
.twitter-typeahead .twitter-typeahead .typeahead {
  width: 100% !important;
}
.twitter-typeahead .twitter-typeahead .tt-menu {
  position: inherit !important;
  top: inherit !important;
  left: inherit !important;
}
.twitter-typeahead .twitter-typeahead .tt-hint {
  display: none !important;
}

.typeahead,
.tt-query {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #eee;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  outline: none;
}

.tt-input {
  margin-bottom: 0 !important;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-menu {
  margin: 0;
  padding: 8px 0;
  max-height: 190px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

@media (min-width: 768px) {
  .isolation-bootstrap-3.d2-0 .navbar-default {
    border: 0;
  }
}
.isolation-bootstrap-3.d2-0 .navbar-default .navbar-nav > li > a,
.isolation-bootstrap-3.d2-0 .navbar-default .navbar-nav > li > a:focus,
.isolation-bootstrap-3.d2-0 .navbar-default .nav-pills > li > a,
.isolation-bootstrap-3.d2-0 .navbar-default .nav-pills > li > a:focus {
  color: #009757;
}
.isolation-bootstrap-3.d2-0 .navbar-default .navbar-nav.navbar-right > li:last-child > a {
  margin-right: 0;
}
.isolation-bootstrap-3.d2-0 .table.table-condensed > thead > tr > th,
.isolation-bootstrap-3.d2-0 .table.table-condensed > thead > tr > td,
.isolation-bootstrap-3.d2-0 .table.table-condensed > tbody > tr > th,
.isolation-bootstrap-3.d2-0 .table.table-condensed > tbody > tr > td,
.isolation-bootstrap-3.d2-0 .table.table-condensed > tfoot > tr > th,
.isolation-bootstrap-3.d2-0 .table.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.isolation-bootstrap-3.d2-0 .tableau-donnees.table-striped > tbody > tr.no-table-striped {
  background-color: transparent;
}
.isolation-bootstrap-3.d2-0 .form-condensed label {
  display: block;
}
.isolation-bootstrap-3.d2-0 .form-condensed .form-group, .isolation-bootstrap-3.d2-0 .form-condensed label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.isolation-bootstrap-3.d2-0 .c-inputs-stacked .form-group .c-input {
  margin-bottom: 0;
}
.isolation-bootstrap-3.d2-0 .btn.btn-xs,
.isolation-bootstrap-3.d2-0 .btn.btn-xs:hover {
  padding: 4px 5px;
  font-size: 1.2rem;
  min-height: 22px;
  min-width: 35px;
}
.isolation-bootstrap-3.d2-0 a.help-link,
.isolation-bootstrap-3.d2-0 a.help-link:visited {
  font: caption;
}
.isolation-bootstrap-3.d2-0 .ic-plus-carre-out-couleur:after {
  content: url("n3/fwd-bootstrap/3.3/img/svg/ic-plus-carre-out-couleur.svg");
}
.isolation-bootstrap-3.d2-0 .ic-moins-carre-out-couleur:after {
  content: url("n3/fwd-bootstrap/3.3/img/svg/ic-moins-carre-out-couleur.svg");
}
.isolation-bootstrap-3 .m-t-4 {
  margin-top: 3rem !important;
}
.isolation-bootstrap-3 .m-r-4 {
  margin-right: 3rem !important;
}
.isolation-bootstrap-3 .m-b-4 {
  margin-bottom: 3rem !important;
}
.isolation-bootstrap-3 .m-l-4 {
  margin-left: 3rem !important;
}
.isolation-bootstrap-3 .m-t-5 {
  margin-top: 5rem !important;
}
.isolation-bootstrap-3 .m-r-5 {
  margin-right: 5rem !important;
}
.isolation-bootstrap-3 .m-b-5 {
  margin-bottom: 5rem !important;
}
.isolation-bootstrap-3 .m-l-5 {
  margin-left: 5rem !important;
}
.isolation-bootstrap-3 .p-t-4 {
  padding-top: 3rem !important;
}
.isolation-bootstrap-3 .p-r-4 {
  padding-right: 3rem !important;
}
.isolation-bootstrap-3 .p-b-4 {
  padding-bottom: 3rem !important;
}
.isolation-bootstrap-3 .p-l-4 {
  padding-left: 3rem !important;
}
.isolation-bootstrap-3 .p-t-5 {
  padding-top: 5rem !important;
}
.isolation-bootstrap-3 .p-r-5 {
  padding-right: 5rem !important;
}
.isolation-bootstrap-3 .p-b-5 {
  padding-bottom: 5rem !important;
}
.isolation-bootstrap-3 .p-l-5 {
  padding-left: 5rem !important;
}
.isolation-bootstrap-3 .panel-input.panel-input-with-field {
  z-index: auto;
}
.isolation-bootstrap-3 .onglets-group {
  max-width: none;
}
.isolation-bootstrap-3 .nav.nav-pills > li.completed > a,
.isolation-bootstrap-3 .nav.nav-pills > li.completed > a:hover,
.isolation-bootstrap-3 .nav.nav-pills > li.completed > a:focus {
  color: #fff;
  background-color: #009757;
  cursor: pointer;
}
.isolation-bootstrap-3 .nav.nav-pills > li.disabled > a,
.isolation-bootstrap-3 .nav.nav-pills > li.disabled > a:hover,
.isolation-bootstrap-3 .nav.nav-pills > li.disabled > a:focus {
  color: #777;
  background-color: transparent;
  cursor: not-allowed;
}
.isolation-bootstrap-3 .root-loading:before {
  content: url("../assets/img/loading.gif");
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1070;
}
.isolation-bootstrap-3 .root-loading:after {
  position: fixed;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
  background-color: #fff;
  opacity: 0.9;
}
.isolation-bootstrap-3 .btn.loadable {
  padding-left: 37px;
  padding-right: 37px;
}
.isolation-bootstrap-3 .btn.loadable.loading {
  padding-right: 37px;
}
.isolation-bootstrap-3 .btn.loadable.loading:before {
  margin-top: -15px;
  content: url("../assets/img/loading.gif");
  position: absolute;
  right: 7px;
  top: 50%;
}
.isolation-bootstrap-3 .btn.loadable.loading.btn-primary:before {
  margin-top: -10px;
  content: url("../assets/img/loading-primary.gif");
  position: absolute;
  right: 10px;
  top: 50%;
}
.isolation-bootstrap-3 .liste-striped > li:nth-of-type(odd) {
  background-color: #f3f5f6;
}
.isolation-bootstrap-3 .text-danger {
  color: #cc0000;
}
.isolation-bootstrap-3 a.disabled {
  pointer-events: none;
}
.isolation-bootstrap-3 .c-input.disabled, .isolation-bootstrap-3 input[type=checkbox][disabled] {
  cursor: not-allowed;
}

.background-light {
  background-color: #F2F2F2 !important;
}

b.email {
  white-space: nowrap;
}

.row.align-items-baseline {
  display: flex;
  align-items: flex-end;
}

.help-link {
  margin-left: 0.75rem;
}

.row.equal, .form-group.equal {
  display: flex;
  flex-wrap: wrap;
}

[class*=col].stretch {
  display: flex;
}

[class*=col].stretch .panel {
  width: 100%;
}

/*breakpoint dépend de la grid*/
@media (max-width: 991px) {
  [class*=col].stretch,
[class*=col].stretch .panel-simple {
    flex: 0 0 100%;
  }
  .row.equal, .form-group.equal {
    flex-wrap: wrap;
  }
}
.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
}

.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #e1e1e1;
}

.isolation-bootstrap-3 .datepicker table th.next,
.isolation-bootstrap-3 .datepicker table th.prev {
  background-image: unset;
  -webkit-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
  transform: unset;
  padding-top: 4px;
}

.padding-right-20 {
  padding-right: 20px;
}

body {
  margin-bottom: 100px;
}

address {
  font-weight: bold;
}

.desjardins {
  color: #009757;
}

.rouge {
  color: #cc0000;
}

.contactus {
  position: relative;
}
.contactus:before {
  font-family: "disnat2";
  position: absolute;
  left: 0;
  font-weight: bold;
  content: "\e617";
}

.b-c-h-icon {
  font-size: 4.2rem;
  margin-right: 3rem;
  color: #009757;
  float: left;
}

.isolation-bootstrap-3.d2-0 .c-checkbox .c-indicator.disabled {
  cursor: not-allowed;
}

input[type=checkbox][disabled] {
  cursor: not-allowed;
}

.form-group #account-types label {
  width: 100%;
}

option:disabled {
  background-color: #d2d2d2;
  color: #595959;
}

#access-links {
  position: absolute;
  top: 2px;
  left: 50%;
  margin-left: -70px;
}

.isolation-bootstrap-3.d2-0 .btn.btn-entity {
  margin: 0;
  padding: 0;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  font-style: normal;
}
.isolation-bootstrap-3.d2-0 .btn.btn-entity.btn-default {
  color: #fff;
  background: #777;
  border-color: #777;
}

.modal-video-iframe {
  width: 100%;
  height: 388px;
}

@media (min-width: 768px) {
  .modal-video-iframe {
    height: 315px;
  }
}
@media (min-width: 992px) {
  .modal-video-iframe {
    height: 485px;
  }
}
@media (min-width: 1280px) {
  .modal-video-iframe {
    height: 485px;
  }
}
.isolation-bootstrap-3.d2-0 .modal .modal-dialog .modal-footer .text-inline {
  order: 3;
  margin: auto;
}

.isolation-bootstrap-3.d2-0 .modal .modal-dialog .modal-body.generation-pdf-01 {
  background: transparent url("../../assets/img/generation-pdf-01.png") no-repeat bottom 30px right 30px;
}
.isolation-bootstrap-3.d2-0 .modal .modal-dialog .modal-body.generation-pdf-01 .generation-pdf-01-text {
  background: rgba(255, 255, 255, 0.8);
}

.isolation-bootstrap-3 .modal-dialog.modal-full-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  width: auto;
  margin: 30px;
}
.isolation-bootstrap-3 .modal-dialog.modal-full-screen .modal-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
  align-self: auto;
}
.isolation-bootstrap-3 .modal-dialog.modal-full-screen .modal-header,
.isolation-bootstrap-3 .modal-dialog.modal-full-screen .modal-footer {
  flex: 0 1 auto;
  align-self: auto;
}
.isolation-bootstrap-3 .modal-dialog.modal-full-screen .modal-body {
  flex: 1 1 auto;
  align-self: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  overflow: hidden;
}
.isolation-bootstrap-3 .modal-dialog.modal-full-screen .modal-body iframe {
  flex: 1 1 auto;
  align-self: auto;
  width: 100%;
}

.panel-background {
  height: 200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: none;
  position: relative;
}
.panel-background > div {
  width: 50%;
  margin-left: 7rem;
}
.panel-background.app-mobile {
  background-image: url("../../assets/img/application.jpg");
}
.panel-background.info-courriel {
  background-image: url("../../assets/img/info-courriel.jpg");
}

.sidebar.last {
  margin-right: 0;
}
.sidebar .sidebar-link {
  border-radius: 3px 3px 0 0;
  background-color: #363636;
  width: 100%;
}
.sidebar .sidebar-img {
  border-radius: 3px 3px 0 0;
  width: 100%;
}
.sidebar.video .sidebar-link {
  text-decoration: none;
  position: relative;
}
.sidebar.video .sidebar-link:hover:before, .sidebar.video .sidebar-link:focus:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  left: 0;
}
.sidebar.video {
  cursor: pointer;
}
.sidebar.video .sidebar-link {
  text-decoration: none;
  position: relative;
}
.sidebar.video .sidebar-link img {
  max-width: inherit;
}
.sidebar.video .sidebar-link h3 {
  color: #009757;
}
.sidebar.video .sidebar-link p {
  color: #000;
}
.sidebar.video .sidebar-link .video-img {
  display: block;
  position: relative;
  background: #000;
}
.sidebar.video .sidebar-link .video-img .duration {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  padding: 10px 14px;
  padding: 1rem 1.4rem;
}
.sidebar.video .sidebar-link .video-img:after {
  font-size: 6.6rem;
  line-height: 6.6rem;
  top: 5.1rem;
  left: calc(50% - 33px);
  font-family: "disnat";
  position: absolute;
  content: "\e60b";
  color: #fff;
  z-index: 2;
}
.sidebar.video .sidebar-link:hover .video-img:before, .sidebar.video .sidebar-link:focus .video-img:before {
  width: 4.5rem;
  height: 4.5rem;
  top: 6.1rem;
  left: calc(50% - 23px);
  background-color: #fff;
  text-decoration: none;
  position: absolute;
  content: "";
  z-index: 1;
}
.sidebar.video .sidebar-link:hover .video-img:after, .sidebar.video .sidebar-link:focus .video-img:after {
  color: #009757;
  content: "\e60c";
}
.sidebar.video .sidebar-link:hover .sidebar-img, .sidebar.video .sidebar-link:focus .sidebar-img {
  opacity: 0.5;
}

.isolation-bootstrap-3 .review-header .badge {
  background-color: #009757;
  font-size: inherit;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 3.4rem;
  font-size: 1.8rem;
  border: 0;
  font-weight: inherit;
  margin-right: 20px;
}
.isolation-bootstrap-3 .review-header-text {
  vertical-align: middle;
}