.modal-video-iframe {
    width: 100%;
    height: 388px
}
@media (min-width: 768px) {
    .modal-video-iframe {
        height: 315px;
    }
}
@media (min-width: 992px) {
    .modal-video-iframe {
        height: 485px;
    }
}
@media (min-width: 1280px) {
    .modal-video-iframe {
        height: 485px;
    }
}
.isolation-bootstrap-3.d2-0 .modal .modal-dialog .modal-footer .text-inline {
    order: 3;
    margin: auto;
}

.isolation-bootstrap-3.d2-0 .modal .modal-dialog .modal-body.generation-pdf-01 {
    background: transparent url('../../assets/img/generation-pdf-01.png') no-repeat bottom 30px right 30px;

    .generation-pdf-01-text {
        background: rgba(255,255,255,.8);
    }
}

.isolation-bootstrap-3 .modal-dialog.modal-full-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    width: auto;
    margin: 30px;

    .modal-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;

        flex: 1 1 auto;
        align-self: auto;
    }

    .modal-header,
    .modal-footer {
        flex: 0 1 auto;
        align-self: auto;
    }

    .modal-body {
        flex: 1 1 auto;
        align-self: auto;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;

        overflow: hidden;

        iframe {
            flex: 1 1 auto;
            align-self: auto;
            width: 100%;
        }
    }
}
