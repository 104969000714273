@media (min-width: 768px) {
    .isolation-bootstrap-3.d2-0 .navbar-default {
        border: 0;
    }
}

.isolation-bootstrap-3 {

    &.d2-0 {
        .navbar-default .navbar-nav > li > a,
        .navbar-default .navbar-nav > li > a:focus,
        .navbar-default .nav-pills > li > a,
        .navbar-default .nav-pills > li > a:focus {
            color: $desjardins;
        }

        .navbar-default .navbar-nav.navbar-right > li:last-child > a {
            margin-right: 0;
        }

        .table.table-condensed > thead > tr > th,
        .table.table-condensed > thead > tr > td,
        .table.table-condensed > tbody > tr > th,
        .table.table-condensed > tbody > tr > td,
        .table.table-condensed > tfoot > tr > th,
        .table.table-condensed > tfoot > tr > td {
            padding: 5px;
        }

        .tableau-donnees.table-striped > tbody > tr.no-table-striped {
            background-color: transparent;
        }

        .form-condensed {

            & label {
                display: block;
            }

            & .form-group,
            & label {
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;

                margin-top: 0;
                margin-right: 0;
                margin-bottom: 0;
                margin-left: 0;
            }
        }

        .c-inputs-stacked .form-group .c-input {
            margin-bottom: 0;
        }

        .btn.btn-xs,
        .btn.btn-xs:hover {
            padding: 4px 5px;
            font-size: 1.2rem;
            min-height: 22px;
            min-width: 35px;
        }

        a.help-link,
        a.help-link:visited {
            font: caption;
        }

        .ic-plus-carre-out-couleur:after {
            content: url("n3/fwd-bootstrap/3.3/img/svg/ic-plus-carre-out-couleur.svg");
        }

        .ic-moins-carre-out-couleur:after {
            content: url("n3/fwd-bootstrap/3.3/img/svg/ic-moins-carre-out-couleur.svg");
        }
    }

    .m-t-4 {
        margin-top: 3rem !important;
    }

    .m-r-4 {
        margin-right: 3rem !important;
    }

    .m-b-4 {
        margin-bottom: 3rem !important;
    }

    .m-l-4 {
        margin-left: 3rem !important;
    }

    .m-t-5 {
        margin-top: 5rem !important;
    }

    .m-r-5 {
        margin-right: 5rem !important;
    }

    .m-b-5 {
        margin-bottom: 5rem !important;
    }

    .m-l-5 {
        margin-left: 5rem !important;
    }

    .p-t-4 {
        padding-top: 3rem !important;
    }

    .p-r-4 {
        padding-right: 3rem !important;
    }

    .p-b-4 {
        padding-bottom: 3rem !important;
    }

    .p-l-4 {
        padding-left: 3rem !important;
    }

    .p-t-5 {
        padding-top: 5rem !important;
    }

    .p-r-5 {
        padding-right: 5rem !important;
    }

    .p-b-5 {
        padding-bottom: 5rem !important;
    }

    .p-l-5 {
        padding-left: 5rem !important;
    }

    .panel-input.panel-input-with-field {
        z-index: auto;
    }

    .onglets-group {
        max-width: none;
    }

    .nav.nav-pills > li.completed > a,
    .nav.nav-pills > li.completed > a:hover,
    .nav.nav-pills > li.completed > a:focus {
        color: #fff;
        background-color: $desjardins;
        cursor: pointer;
    }

    .nav.nav-pills > li.disabled > a,
    .nav.nav-pills > li.disabled > a:hover,
    .nav.nav-pills > li.disabled > a:focus {
        color: #777;
        background-color: transparent;
        cursor: not-allowed;
    }

    .root-loading {

        &:before {
            content: url("../assets/img/loading.gif");
            position: fixed;
            left: 50%;
            top: 50%;
            z-index: 1070;
        }

        &:after {
            position: fixed;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1060;
            background-color: #fff;
            opacity: .9;
        }
    }

    .btn.loadable {
        padding-left: 37px;
        padding-right: 37px;

        &.loading {
            padding-right: 37px;

            &:before {
                margin-top: -15px;
                content: url("../assets/img/loading.gif");
                position: absolute;
                right: 7px;
                top: 50%;
            }

            &.btn-primary:before {
                margin-top: -10px;
                content: url("../assets/img/loading-primary.gif");
                position: absolute;
                right: 10px;
                top: 50%;
            }
        }
    }

    .liste-striped > li:nth-of-type(odd) {
        background-color: #f3f5f6;
    }

    .text-danger {
        color: #cc0000;
    }

    a.disabled {
        pointer-events: none;
    }

    .c-input.disabled, input[type="checkbox"][disabled] {
        cursor: not-allowed;
    }
}

