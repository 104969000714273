
$entity-size : 3rem;

.isolation-bootstrap-3.d2-0 .btn.btn-entity {
    margin: 0;
    padding: 0;
    width: $entity-size;
    min-width: $entity-size;
    height: $entity-size;
    min-height: $entity-size;
    font-size: $entity-size;
    line-height: $entity-size;
    border-radius: 50%;
    font-style: normal;

    &.btn-default {
        color: #fff;
        background: #777;
        border-color: #777;
    }
}
