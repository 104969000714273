.row.align-items-baseline {
    display: flex;
    align-items: flex-end;
}

.help-link {
    margin-left: .75rem;
}

.row.equal, .form-group.equal {
    display: flex;
    flex-wrap: wrap;
}
[class*=col].stretch {
    display: flex;
}

[class*=col].stretch .panel{
    width:100%;
}

/*breakpoint dépend de la grid*/
@media (max-width: 991px) {
    [class*=col].stretch,
    [class*=col].stretch .panel-simple {
        flex: 0 0 100%;
    }

    .row.equal, .form-group.equal {
        flex-wrap: wrap;
    }
}

.datepicker table tr td,
.datepicker table tr th {
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: none;
}
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #e1e1e1;
}
.isolation-bootstrap-3 .datepicker table th.next,
.isolation-bootstrap-3 .datepicker table th.prev {
    background-image: unset;
    -webkit-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    transform: unset;
    padding-top: 4px;
}
.padding-right-20 {
    padding-right: 20px;
}
