.twitter-typeahead .twitter-typeahead {
    position: inherit !important;
    display: inherit !important;

    .typeahead {
        width: 100% !important;
    }

    .tt-menu {
        position: inherit !important;
        top: inherit !important;
        left: inherit !important;
    }

    .tt-hint {
        display: none !important;
    }
}

.typeahead,
.tt-query {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #eee;
  -webkit-border-radius: 0px;
     -moz-border-radius: 0px;
          border-radius: 0px;
  outline: none;
}

.tt-input {
    margin-bottom: 0 !important;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-menu {
  margin: 0;
  padding: 8px 0;
  max-height: 190px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0px;
     -moz-border-radius: 0px;
          border-radius: 0px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}
