.contactus {
    position: relative;

    &:before {
        font-family: 'disnat2';
        position: absolute;
        left: 0;
        font-weight: bold;
        content: "\e617";
    }
}

.b-c-h-icon{
    font-size: 4.2rem;
    margin-right: 3rem;
    color: $desjardins;
    float: left;
}
