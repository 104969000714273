.isolation-bootstrap-3 {
    .review-header .badge {
        background-color: $desjardins;
        font-size: inherit;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        line-height: 3.4rem;
        font-size: 1.8rem;
        border: 0;
        font-weight: inherit;
        margin-right: 20px;
    }

    .review-header-text {
        vertical-align: middle;
    }
}
