.panel-background {
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: none;
    position: relative;

    > div {
        width: 50%;
        margin-left: 7rem;
    }

    &.app-mobile {
        background-image: url('../../assets/img/application.jpg');
    }

    &.info-courriel {
        background-image: url('../../assets/img/info-courriel.jpg');
    }
}

.sidebar {
    &.last {
        margin-right: 0;
    }
    .sidebar-link {
        border-radius: 3px 3px 0 0;
        background-color: #363636;
        width: 100%;
    }
    .sidebar-img {
        border-radius: 3px 3px 0 0;
        width: 100%;
    }

    &.video .sidebar-link {
        text-decoration: none;
        position: relative;
        &:hover:before,
        &:focus:before {
            content: "";
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            cursor: pointer;
            left: 0;
        }
    }

    &.video {
        cursor: pointer;
        .sidebar-link {
            text-decoration: none;
            position: relative;
            img {
                max-width: inherit;
            }
            h3 {
                color: $desjardins;
            }
            p {
                color: #000;
            }
            .video-img {
                display: block;
                position: relative;
                background: #000;

                .duration {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background: #000;
                    color: #fff;
                    padding: 10px 14px;
                    padding: 1rem 1.4rem;
                }
                &:after {
                    font-size: 6.6rem;
                    line-height: 6.6rem;

                    top: 5.1rem;
                    left: calc(50% - 33px);;
                    font-family: 'disnat';
                    position: absolute;
                    content: "\e60b";
                    color: #fff;
                    z-index: 2;

                }
            }
            &:hover .video-img:before,
            &:focus .video-img:before {
                width: 4.5rem;
                height: 4.5rem;
                top: 6.1rem;
                left: calc(50% - 23px);;
                background-color: #fff;
                text-decoration: none;
                position: absolute;
                content: "";
                z-index: 1;
            }
            &:hover .video-img:after,
            &:focus .video-img:after {
                color: $desjardins;
                content: "\e60c";
            }

            &:hover .sidebar-img,
            &:focus .sidebar-img {
                opacity: .5;
            }
        }

    }

}
