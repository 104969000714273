//	------------------------------------------------------------
//	FONTS
//	-----------------------------------------------------------*/
@include font-face(oswald, '#{$fonts-path}/oswald');
@include font-face(oswald-bold, '#{$fonts-path}/oswald-bold');
@include font-face(oswald-light, '#{$fonts-path}/oswald-light');
@include font-face(disnat, '#{$fonts-path}/disnat');
@include font-face(disnat2, '#{$fonts-path}/disnat2');



[class^="icon-"],
[class*=" icon-"],
.icon-disnat {
	font-family: 'disnat';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	outline:0;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-disnat2 {
	font-family: 'disnat2';
}



/* disnat 2 */
.icon-circle-plus-light:before {
	content: "\e621";
}
.icon-file-web:before {
	content: "\e61d";
}
.icon-file-pdf:before {
	content: "\e61e";
}
.icon-file-excel:before {
	content: "\e61f";
}
.icon-file-word:before {
	content: "\e620";
}
.icon-phone-up:before {
	content: "\e61b";
}
.icon-fax:before {
	content: "\e61c";
}
.icon-windows:before {
	content: "\e60c";
}
.icon-apple:before {
	content: "\e60d";
}
.icon-user-to-user:before {
	content: "\e60e";
}
.icon-students:before {
	content: "\e60f";
}
.icon-book-roof:before {
	content: "\e610";
}
.icon-change:before {
	content: "\e611";
}
.icon-parasol:before {
	content: "\e612";
}
.icon-bill:before {
	content: "\e613";
}
.icon-house:before {
	content: "\e614";
}
.icon-laptop:before {
	content: "\e615";
}
.icon-gear:before {
	content: "\e616";
}
.icon-phone-line:before {
	content: "\e617";
}
.icon-group-line:before {
	content: "\e618";
}
.icon-users-line:before {
	content: "\e619";
}
.icon-user-line:before {
	content: "\e61a";
}
.icon-user:before {
	content: "\e600";
}
.icon-comment:before {
	content: "\e601";
}
.icon-clock-bold:before {
	content: "\e602";
}
.icon-zoom-plus:before {
	content: "\e605";
}
.icon-zoom-minus:before {
	content: "\e603";
}
.icon-zoom:before {
	content: "\e604";
}
.icon-download:before {
	content: "\e607";
}
.icon-file:before {
	content: "\e606";
}
.icon-table:before {
	content: "\e609";
}
.icon-graph-dot:before {
	content: "\e608";
}
.icon-check-light:before {
	content: "\e60b";
}
.icon-arrow-right-light:before {
	content: "\e60a";
}

/* disnat 1 */
.icon-menu:before {
	content: "\e637";
}
.icon-triangle-small-up:before {
	content: "\e638";
}
.icon-triangle-small-down:before {
	content: "\e639";
}
.icon-arrow-up:before {
	content: "\e63a";
}
.icon-arrow-down:before {
	content: "\e63b";
}
.icon-circle-close:before {
	content: "\e628";
}
.icon-book:before {
	content: "\e629";
}
.icon-user-bold:before {
	content: "\e62a";
}
.icon-print:before {
	content: "\e62b";
}
.icon-clock:before {
	content: "\e62c";
}
.icon-circle-arrow-down:before {
	content: "\e62d";
}
.icon-circle-plus:before {
	content: "\e62e";
}
.icon-stat:before {
	content: "\e62f";
}
.icon-check:before {
	content: "\e630";
}
.icon-expert:before {
	content: "\e631";
}
.icon-intermediary:before {
	content: "\e632";
}
.icon-novice:before {
	content: "\e633";
}
.icon-files:before {
	content: "\e634";
}
.icon-direction-up:before {
	content: "\e635";
}
.icon-direction-down:before {
	content: "\e636";
}
.icon-thumb-up:before {
	content: "\e61d";
}
.icon-graph:before {
	content: "\e61e";
}
.icon-compass:before {
	content: "\e61f";
}
.icon-monitor:before {
	content: "\e620";
}
.icon-icon-desjardins:before {
	content: "\e621";
}
.icon-map:before {
	content: "\e622";
}
.icon-mail-closed:before {
	content: "\e623";
}
.icon-movie:before {
	content: "\e624";
}
.icon-print:before {
	content: "\e625";
}
.icon-share:before {
	content: "\e626";
}
.icon-bookmark:before {
	content: "\e627";
}
.icon-font-increase:before {
	content: "\e61b";
}
.icon-font-decrease:before {
	content: "\e61c";
}
.icon-calendar:before {
	content: "\e60e";
}
.icon-circle-clock:before {
	content: "\e60f";
}
.icon-circle-dollars:before {
	content: "\e610";
}
.icon-circle-interrogation:before {
	content: "\e611";
}
.icon-circle-map:before {
	content: "\e612";
}
.icon-circle-user:before {
	content: "\e613";
}
.icon-discussion:before {
	content: "\e614";
}
.icon-loupe:before {
	content: "\e615";
}
.icon-mail:before {
	content: "\e616";
}
.icon-triangle-left:before {
	content: "\e617";
}
.icon-triangle-right:before {
	content: "\e618";
}
.icon-up-down:before {
	content: "\e619";
}
.icon-user-add:before {
	content: "\e61a";
}
.icon-up-down2:before {
	content: "\e600";
}
.icon-arrow-left:before {
	content: "\e601";
}
.icon-arrow-right:before {
	content: "\e602";
}
.icon-circle-arrow-left:before {
	content: "\e603";
}
.icon-circle-arrow-left-hover:before {
	content: "\e604";
}
.icon-circle-arrow-right:before {
	content: "\e605";
}
.icon-circle-arrow-right-hover:before {
	content: "\e606";
}
.icon-close:before {
	content: "\e607";
}
.icon-direction-left:before {
	content: "\e608";
}
.icon-direction-right:before {
	content: "\e609";
}
.icon-lock:before {
	content: "\e60a";
}
.icon-play:before {
	content: "\e60b";
}
.icon-play-hover:before {
	content: "\e60c";
}
.icon-plus:before {
	content: "\e60d";
}
