
.isolation-bootstrap-3.d2-0 .c-checkbox .c-indicator.disabled {
    cursor: not-allowed;
}
input[type="checkbox"][disabled] {
    cursor: not-allowed;
}

.form-group #account-types label {
    width: 100%
}

option:disabled {
    background-color: #d2d2d2;
    color: #595959;
}
